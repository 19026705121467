<template>
  <v-card class="pa-5 mb-5" flat>
    <div class="font-weight-bold mb-5">
      Thiết lập SEO mặc định
    </div>

    <div
      v-for="item in LIST_SITE_CONFIG_ORGANIZATION_PROPERTIES"
      :key="item.value"
    >
      <div class="font-weight-bold mb-3 text-body-1">
        {{ item.text }}
      </div>

      <tp-textarea
        v-if="item.text === 'Mô tả trang'"
        v-model="organization.description"
        class="text-body-1"
        placeholder="Nhập mô tả"
        rows="3"
        :style="{ width: 400 + 'px' }"
        validate="required|max:200"
      ></tp-textarea>

      <tp-combobox
        v-else-if="item.text === 'Từ khóa'"
        v-model="organization.keywords"
        deletable-chips
        placeholder="Nhập từ khóa"
        multiple
        small-chips
        :style="{ width: 400 + 'px' }"
        validate="required|min:1"
      >
      </tp-combobox>

      <tp-text-field
        v-else-if="item.text === 'Tiêu đề trang'"
        v-model="organization[item.value]"
        auto-grow
        placeholder="Nhập dữ liệu"
        :style="{ width: 400 + 'px' }"
        validate="required|max:120"
      ></tp-text-field>

      <tp-text-field
        v-else-if="item.text === 'Tên thương hiệu'"
        v-model="organization[item.value]"
        auto-grow
        placeholder="Ví dụ: ThinkPro"
        :style="{ width: 400 + 'px' }"
        validate="required"
      ></tp-text-field>

      <tp-text-field
        v-else
        v-model="organization[item.value]"
        auto-grow
        placeholder="Nhập dữ liệu"
        :style="{ width: 400 + 'px' }"
        validate="required"
      ></tp-text-field>
    </div>
  </v-card>
</template>
<script>
import { LIST_SITE_CONFIG_ORGANIZATION_PROPERTIES } from "@/modules/Website/constants";
export default {
  data() {
    return {
      LIST_SITE_CONFIG_ORGANIZATION_PROPERTIES
    };
  },
  computed: {
    siteConfig() {
      return this.$store.state.SITE_CONFIG.siteConfig;
    },
    organization() {
      return this.siteConfig.organization;
    }
  }
};
</script>
