<template>
  <v-card class="pa-5 mb-5" flat>
    <div>
      <div class="font-weight-bold mb-5">
        Thông tin hữu ích
      </div>

      <div
        v-for="item in LIST_SITE_CONFIG_USEFUL_INFORMATION"
        :key="item.value"
      >
        <div class="font-weight-bold mb-3 text-body-1">
          {{ item.text }}
        </div>

        <tp-text-field
          v-model="siteConfig.useful_information[item.value]"
          auto-grow
          placeholder="Nhập đường dẫn"
          :style="{ width: 400 + 'px' }"
          validate="required"
        ></tp-text-field>
      </div>
    </div>
  </v-card>
</template>

<script>
import { LIST_SITE_CONFIG_USEFUL_INFORMATION } from "@/modules/Website/constants";

export default {
  data() {
    return {
      LIST_SITE_CONFIG_USEFUL_INFORMATION
    };
  },
  computed: {
    siteConfig() {
      return this.$store.state.SITE_CONFIG.siteConfig;
    }
  }
};
</script>
