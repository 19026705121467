<template>
  <!-- Start: Top -->
  <div class="d-flex align-center mb-5">
    <div class="text-h5 font-weight-bold mr-4">
      Chung
    </div>

    <v-chip-group v-model="activeTab" mandatory>
      <div v-for="(site, index) in sites" :key="index" class="d-flex">
        <v-chip
          class="font-weight-bold rounded-lg px-4"
          :color="activeTab === index ? 'white' : 'grey lighten-3'"
          label
          large
          style="height: 36px"
          :text-color="activeTab === index ? 'primary' : 'grey darken-2'"
        >
          {{ site.text }}
        </v-chip>

        <div
          class="py-2"
          style="height: 100%"
          v-if="index !== sites.length - 1"
        >
          <v-divider class="ml-1 mr-3" vertical></v-divider>
        </div>
      </div>
    </v-chip-group>

    <v-spacer></v-spacer>

    <v-btn
      :loading="isLoading"
      class="rounded-lg"
      color="primary"
      depressed
      :disabled="isLoading"
      @click="updateSiteConfig"
    >
      Lưu
    </v-btn>
  </div>
  <!-- End: Top -->
</template>

<script>
import { LIST_MODEL_SITES } from "@/core/constant";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import { filter } from "@vt7/utils";

export default {
  props: {
    isValid: {
      type: Boolean,
      require: true
    }
  },

  data() {
    return {
      activeTab: 0,
      LIST_MODEL_SITES,
      isLoading: false
    };
  },

  created() {
    this.$store.dispatch("SITE_CONFIG/getSideConfig", this.activeSite);
  },

  computed: {
    activeSite() {
      if (this.activeTab || this.activeTab === 0) {
        return this.sites[this.activeTab].id;
      }
      return 0;
    },

    sites() {
      return Object.values(this.LIST_MODEL_SITES);
    },

    siteConfig() {
      return this.$store.state.SITE_CONFIG.siteConfig;
    },

    siteConfigStatusRequest() {
      return this.$store.state.SITE_CONFIG.statusRequest;
    }
  },

  methods: {
    async uploadImages() {
      const filteredSiteConfigFile = filter(
        this.siteConfig,
        item => item instanceof File
      );

      await this.$store.dispatch(
        "SITE_CONFIG/uploadImages",
        filteredSiteConfigFile
      );
    },

    async updateSiteConfig() {
      this.isLoading = true;

      await this.$emit("checkValidateForm");

      if (!this.isValid) {
        showModalAlertError(this, {
          title: "Không thể lưu cập nhật",
          message:
            "Dữ liệu nhập vào không hợp lệ, vui lòng kiểm tra và thử lại."
        });
        this.isLoading = false;

        return;
      }

      await this.uploadImages();

      if (this.siteConfigStatusRequest.value === "error-uploadImages") {
        showModalAlertError(this, {
          title: "Lỗi tải ảnh lên",
          message: this.siteConfigStatusRequest.message
        });

        this.isLoading = false;

        return;
      }

      if (this.siteConfigStatusRequest.value === "success-uploadImages") {
        await this.$store.dispatch("SITE_CONFIG/updateSiteConfig", {
          site: this.activeSite,
          payload: this.siteConfig
        });

        // Alert
        if (this.siteConfigStatusRequest.value === "success-updateSiteConfig") {
          showToastActionAlert(this, { message: "Đã lưu thay đổi" });
          this.isLoading = false;

          this.$emit("resetFormData");
        } else if (
          this.siteConfigStatusRequest.value === "error-updateSiteConfig"
        ) {
          showModalAlertError(this, {
            title: "Lỗi thay đổi cập nhật",
            message: this.siteConfigStatusRequest.message
          });

          this.isLoading = false;
        }
      }
    }
  },

  watch: {
    activeTab() {
      this.$store.dispatch("SITE_CONFIG/getSideConfig", this.activeSite);
    }
  }
};
</script>
