<template>
  <v-row>
    <v-col cols="12" md="6" xl="4">
      <card-general></card-general>

      <card-seo></card-seo>

      <card-social></card-social>

      <card-useful-information></card-useful-information>
    </v-col>

    <!-- site config only sell online -->
    <v-col cols="12" md="6" xl="4">
      <card-only-online-selling></card-only-online-selling>
    </v-col>
  </v-row>
</template>

<script>
import CardGeneral from "./components/CardGeneral";
import CardUsefulInformation from "./components/CardUsefulInformation";
import CardSeo from "./components/CardSeo";
import CardOnlyOnlineSelling from "./components/CardOnlyOnlineSelling/index.vue";
import CardSocial from "./components/CardSocial";

export default {
  components: {
    CardGeneral,
    CardUsefulInformation,
    CardSeo,
    CardOnlyOnlineSelling,
    CardSocial
  }
};
</script>
