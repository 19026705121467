<template>
  <div>
    <tab-sites
      :isValid="isValid"
      @checkValidateForm="checkValidateForm"
    ></tab-sites>

    <v-form ref="formSiteConfig" v-model="isValid" lazy-validation>
      <form-site-config></form-site-config>
    </v-form>
  </div>
</template>

<script>
import TabSites from "./components/TabSites";
import FormSiteConfig from "./components/FormSiteConfig/index.vue";

export default {
  components: {
    TabSites,
    FormSiteConfig
  },

  data() {
    return {
      isValid: true
    };
  },

  methods: {
    checkValidateForm() {
      this.$refs.formSiteConfig.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.scrollable {
  max-height: calc(100vh - 64px - 53px - 52px - 20px - 24px);
  overflow: scroll;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 52px - 20px - 24px);
  }
}
</style>
