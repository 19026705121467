<template>
  <div class="mb-4">
    <div class="d-flex justify-space-between">
      <div class="font-weight-bold text-body-1">
        Ảnh bìa trang chỉ bán online
      </div>
    </div>

    <!-- Start: Cover image for only sell online note -->
    <div class="yellow lighten-5 rounded px-3 py-2 mb-4 text-body-1 mt-2">
      Kích thước ảnh bìa khuyến nghị
      <span class="font-weight-bold">là 2400 x 640 px</span>
    </div>
    <!-- End: Cover image for only sell online note -->

    <tp-input-image
      :acceptedFileTypes="['image/jpeg']"
      :height="320 * 2"
      :maxFileSize="500 * 1024"
      :src.sync="siteConfig.online_thumbnail"
      :width="1200 * 2"
      allowCompress
      allowFileDimensionValidation
      allowFileSize
      allowFileTypeValidation
      forceType="jpeg"
    ></tp-input-image>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {
    siteConfig() {
      return this.$store.state.SITE_CONFIG.siteConfig;
    }
  }
};
</script>
