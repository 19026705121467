<template>
  <v-card class="pa-5 mb-5" flat>
    <div>
      <div class="font-weight-bold mb-5">
        Social Networks
      </div>

      <div v-for="item in LIST_SITE_CONFIG_SOCIAL_PROPERTIES" :key="item.value">
        <div class="font-weight-bold mb-3 text-body-1">
          {{ item.text }}
        </div>

        <tp-text-field
          v-model="social[item.value].href"
          auto-grow
          placeholder="Nhập dữ liệu"
          :style="{ width: 400 + 'px' }"
          validate="required"
        ></tp-text-field>
      </div>
    </div>
  </v-card>
</template>

<script>
import { LIST_SITE_CONFIG_SOCIAL_PROPERTIES } from "@/modules/Website/constants";
export default {
  data() {
    return {
      LIST_SITE_CONFIG_SOCIAL_PROPERTIES
    };
  },
  computed: {
    siteConfig() {
      return this.$store.state.SITE_CONFIG.siteConfig;
    },
    social() {
      return this.siteConfig.social;
    }
  }
};
</script>
