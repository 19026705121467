<template>
  <v-card class="pa-5 mb-5" flat>
    <only-online-selling-seo></only-online-selling-seo>

    <only-online-selling-banner></only-online-selling-banner>

    <only-online-selling-thumbnail></only-online-selling-thumbnail>

    <only-online-selling-sale-policy></only-online-selling-sale-policy>
  </v-card>
</template>

<script>
import OnlyOnlineSellingBanner from "./components/OnlyOnlineSellingBanner";
import OnlyOnlineSellingSalePolicy from "./components/OnlyOnlineSellingSalePolicy";
import OnlyOnlineSellingSeo from "./components/OnlyOnlineSellingSeo";
import OnlyOnlineSellingThumbnail from "./components/OnlyOnlineSellingThumbnail";

export default {
  components: {
    OnlyOnlineSellingBanner,
    OnlyOnlineSellingSalePolicy,
    OnlyOnlineSellingSeo,
    OnlyOnlineSellingThumbnail
  }
};
</script>
<style></style>
