<template>
  <div>
    <div class="font-weight-bold mb-5">
      Thiết lập chỉ bán online
    </div>

    <div
      v-for="item in LIST_SITE_CONFIG_ONLY_SELL_ONLINE_PROPERTIES"
      :key="item.value"
    >
      <div class="font-weight-bold mb-3 text-body-1">
        {{ item.text }}
      </div>

      <tp-text-field
        v-model="siteConfig.online_seo_data.title"
        v-if="item.text === 'Tiêu đề trang'"
        auto-grow
        counter="120"
        placeholder="Nhập dữ liệu"
        :style="{ width: 400 + 'px' }"
        validate="required|max:120"
      ></tp-text-field>

      <tp-textarea
        v-model="siteConfig.online_seo_data.description"
        v-else-if="item.text === 'Mô tả trang'"
        class="text-body-1"
        counter="400"
        placeholder="Nhập mô tả"
        rows="3"
        :style="{ width: 400 + 'px' }"
        validate="required|max:400"
      ></tp-textarea>

      <tp-combobox
        v-model="siteConfig.online_seo_data.tags"
        v-else-if="item.text === 'Từ khóa'"
        deletable-chips
        placeholder="Nhập từ khóa"
        multiple
        small-chips
        :style="{ width: 400 + 'px' }"
        validate="required|min:1"
      >
      </tp-combobox>
    </div>
  </div>
</template>

<script>
import { LIST_SITE_CONFIG_ONLY_SELL_ONLINE_PROPERTIES } from "@/modules/Website/constants";

export default {
  data() {
    return {
      LIST_SITE_CONFIG_ONLY_SELL_ONLINE_PROPERTIES
    };
  },

  computed: {
    siteConfig() {
      return this.$store.state.SITE_CONFIG.siteConfig;
    }
  }
};
</script>
