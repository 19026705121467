<template>
  <v-card class="pa-5 mb-5" flat>
    <div class="font-weight-bold mb-5">
      Thông tin chung
    </div>

    <div>
      <div class="font-weight-bold mb-3 text-body-1">
        Logo
      </div>

      <div class="img-container">
        <tp-input-image
          :acceptedFileTypes="['image/svg+xml']"
          :src.sync="siteConfig.logo"
          allowFileTypeValidation
          required
        ></tp-input-image>
      </div>
    </div>

    <div>
      <div class="font-weight-bold mt-4 mb-3 text-body-1">
        Favicon
      </div>

      <div class="yellow lighten-5 rounded px-3 py-2 mb-4 text-body-1 mt-2">
        Ảnh trên góc của tab web và ảnh hiển thị khi tìm kiếm trên google
        <span class="font-weight-bold">bắt buộc là 48 x 48 px</span>
      </div>

      <div class="img-container">
        <tp-input-image
          :acceptedFileTypes="['image/png']"
          :height="48"
          :src.sync="siteConfig.favicon"
          :width="48"
          allowFileDimensionValidation
          allowFileTypeValidation
          forceType="png"
          required
        ></tp-input-image>
      </div>
    </div>

    <div>
      <div class="font-weight-bold mt-4 mb-3 text-body-1">
        App icon
      </div>

      <div class="yellow lighten-5 rounded px-3 py-2 mb-4 text-body-1 mt-2">
        Biểu tượng trên điện thoại khi thêm website vào màn hình chính. Kích
        thước yêu cầu là
        <span class="font-weight-bold">bắt buộc là 512 x 512 px</span>
      </div>

      <div class="img-container">
        <tp-input-image
          :acceptedFileTypes="['image/png']"
          :height="512"
          :maxFileSize="50 * 1024"
          :src.sync="siteConfig.icon"
          :width="512"
          allowCompress
          allowFileDimensionValidation
          allowFileSize
          allowFileTypeValidation
          forceType="png"
          required
        ></tp-input-image>
      </div>
    </div>

    <div>
      <div class="font-weight-bold mt-4 mb-3 text-body-1">
        Ảnh dự phòng
      </div>

      <div class="yellow lighten-5 rounded px-3 py-2 mb-4 text-body-1 mt-2">
        Ảnh dự phòng được sử dụng thay thế khi không tìm thấy hình ảnh sản phẩm
        trên website. Kích thước yêu cầu là
        <span class="font-weight-bold"> 1024 x 1024 px</span>
      </div>

      <div class="img-container">
        <tp-input-image
          :acceptedFileTypes="['image/jpeg']"
          :height="1024"
          :maxFileSize="50 * 1024"
          :src.sync="siteConfig.fallback_image"
          :width="1024"
          allowCompress
          allowFileDimensionValidation
          allowFileSize
          allowFileTypeValidation
          forceType="jpeg"
          required
        ></tp-input-image>
      </div>
    </div>

    <div :style="{ width: 400 + 'px' }">
      <div class="font-weight-bold pa-0 mb-3 mt-4 text-body-1">
        Màu theme
      </div>
      <input-color-picker
        v-model="siteConfig.theme_color"
        :rules="[v => !!v || 'Không được bỏ trống']"
      ></input-color-picker>
    </div>

    <div>
      <div class="font-weight-bold pa-0 mb-3 mt-4 text-body-1">
        Hotline
      </div>

      <tp-text-field
        v-model="siteConfig.tel"
        :style="{ width: 400 + 'px' }"
        auto-grow
        placeholder="Nhập dữ liệu"
        type="number"
        validate="required"
      ></tp-text-field>
    </div>

    <div>
      <div class="font-weight-bold pa-0 mb-3 mt-1 text-body-1aaaaaa">
        Số sản phẩm trên cùng một hàng
      </div>

      <tp-text-field
        v-model="siteConfig.number_product_of_line"
        :style="{ width: 400 + 'px' }"
        auto-grow
        placeholder="Nhập dữ liệu"
        type="number"
        validate="required|minVal:3"
      ></tp-text-field>
    </div>
  </v-card>
</template>

<script>
import InputColorPicker from "./InputColorPicker.vue";

export default {
  components: {
    InputColorPicker
  },
  data() {
    return {};
  },
  computed: {
    siteConfig() {
      return this.$store.state.SITE_CONFIG.siteConfig;
    }
  }
};
</script>
