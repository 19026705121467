<template>
  <v-text-field
    v-model="internalValue"
    :label="label"
    class="ma-0 pa-0 color-picker text-body-1"
    dense
    outlined
    v-bind="$attrs"
  >
    <template v-slot:append>
      <v-menu
        v-model="menu"
        top
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on"></div>
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="internalValue"
              flat
              show-swatches
              swatches-max-height="150"
            ></v-color-picker>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  data: () => ({
    menu: false
  }),
  props: {
    value: {
      type: String,
      default: "#000000"
    },
    label: {
      type: String
    }
  },
  computed: {
    swatchStyle() {
      const { menu } = this;
      return {
        backgroundColor: this.internalValue,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        border: "1px solid #9E9E9E",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    },
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
<style>
.color-picker .v-input__append-inner {
  margin-top: 5px !important;
}
</style>
