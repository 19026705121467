<template>
  <div>
    <div class="font-weight-bold text-body-1 mb-3">Chính sách bán hàng</div>

    <ThinkTiptap
      v-model="siteConfig.online_sales_policy"
      :config="config"
      class="tp-tiptap"
    ></ThinkTiptap>

    <tp-text-field
      v-model="siteConfig.online_sales_policy"
      class="sale-policy-content__text-field"
      validate="required"
    ></tp-text-field>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        baseURL: process.env.VUE_APP_THINKPRO_FRONT_STORE,
        imageUploadFolder: "core/site-configs",
        sites: ["THINKPRO", "GOODSPACE"]
      }
    };
  },

  computed: {
    siteConfig() {
      return this.$store.state.SITE_CONFIG.siteConfig;
    }
  }
};
</script>

<style lang="scss" scoped>
.tp-tiptap {
  max-height: 700px;
  resize: vertical;
  overflow: auto;
}

.sale-policy-content__text-field {
  ::v-deep .v-input__slot {
    display: none;
  }

  ::v-deep .v-text-field__details {
    margin-top: 8px;
    margin-bottom: 0;
  }
}
</style>
